/* html,body,#root{
  height: 100%;
  width: 100%;  
} */
*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
body{
  background-color: whitesmoke;
}