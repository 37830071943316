@font-face {
    font-family: 'Azonix';
    src: url('AzonixRegular.woff2') format('woff2'),
        url('AzonixRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Metropolis';
    src: url('Metropolis-Regular.woff2') format('woff2'),
        url('Metropolis-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

